import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import { pl } from 'date-fns/locale';

import { handleSubmit } from "../components/contactForm/formHandler";
import Layout from "../components/layout";
import Section from "../components/section/Section";
import Form from "../components/contactForm/Form";
import Contact from "../components/contact/Contact"

const SprawdzTermin = ({data: {formData: {edges: formData}, houses: {edges: houses}}}) => {
  let actualDate = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(actualDate.getTime() + 7 * 24 * 60 * 60 * 1000));
  
  return (
      <Layout post>
        <Section header={formData[0].node.title} hideHeader className="pt-lg-18">
          <Form title={formData[0].node.title}
                subtitle={<span className="fs-1 fw-light">{formData[0].node.acf.subtitle}</span>}>
            <div className="col-lg-8 d-flex align-items-center">
              <form onSubmit={handleSubmit} name="booking" className="form-ipt">
                <label className="form-spacer-washer" htmlFor="dates">Zaznacz proponowane daty przyjazdu i
                  odjazdu.</label>
                <div>
                  <DatePicker
                    className="form-ipt-small"
                    name="from"
                    locale={pl}
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    minDate={startDate}
                    endDate={endDate}
                    dateFormat="dd-MM-yyyy"
                    required
                  />
                  <DatePicker
                    className="form-ipt-small"
                    name="to"
                    locale={pl}
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd-MM-yyyy"
                    required
                  />
                </div>
                <label className="form-spacer-washer" htmlFor="house">Oznacz dom, który Cię interesuje</label>
                <select className="form-ipt-medium" id="house" name="house" required placeholder="Wybierz z listy"
                        defaultValue="0">
                  <option disabled value="0">Wybierz z listy</option>
                  {houses.map((item, i) => {
                    return (
                        <option key={i + 1} value={item.node.title}>{item.node.title} {item.node.acf.space} m² -
                          dla {item.node.acf.people} osób {item.node.acf.availability_date ? '(od ' + item.node.acf.availability_date + ')' : ''}</option>
                    )
                  })}
                </select>
                <label className="form-spacer-washer" htmlFor="email">Podaj swoje dane, abyśmy mogli
                  odpowiedzieć</label>
                <input name="email" className="form-ipt-big" type="email" id="email" required placeholder="Twój adres e-mail"/>
                <input name="name" className="form-ipt-small" type="text" required placeholder="Imię"/>
                <div className="d-flex flex-row form-spacer">
                  <input type="checkbox" id="rodo" name="rodo" required value="agreed"/>
                  <label className="form-spacer-label" htmlFor="rodo"> Wyrażam zgodę na przetwarzanie moich danych
                    osobowych zgodnie z ustawą z dnia 29
                    sierpnia 1997 r. o ochronie danych osobowych (Dz.U. z 2014 r. poz. 1182 ze zm.) w zakresie
                    podanym w formularzu, w celu przedstawienia mi oferty.</label>
                </div>
                <input className="form-ipt-submit btn btn-primary" value="Sprawdź termin" type="submit"/>
              </form>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <Contact/>
            </div>
          </Form>
        </Section>
      </Layout>
  )
}

export const formQuery = graphql`
    query {
      formData: allWordpressPage(filter: {wordpress_id: {eq: 223}}) {
        edges {
         node {
            title
            acf {
              subtitle
            }
         }
        }
      }
      houses: allWordpressWpHouses(sort: {fields: wordpress_id, order: ASC}) {
        edges {
          node {
            acf {
              people
              space
              icon {
                localFile {
                  publicURL
                }
              }
              availability_date
            }
            slug
            title
            wordpress_id
          }
        }
      }
    }
`;


export default SprawdzTermin
